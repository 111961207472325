import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import zxcvbn from 'zxcvbn';
import './Login.css';


const Login = () => {
  const [signupEmail, setSignupEmail] = useState('');
  const [signupEmailValid, setSignupEmailValid] = useState(null);

  useEffect(() => {
    const valid = validateEmail(signupEmail);
    setSignupEmailValid(valid);
    // console.log('is signupEmailValid working?', signupEmailValid);
    console.log('useEffect validation result:', valid);
  }, [signupEmail]);

  const [signupPassword, setSignupPassword] = useState('');
  const [signupPasswordValid, setSignupPasswordValid] = useState(null);

  useEffect(() => {
    const valid = validatePassword(signupPassword);
    setSignupPasswordValid(valid);
  }, [signupPassword]);

  const [loginEmail, setLoginEmail] = useState('');
  const [loginEmailValid, setLoginEmailValid] = useState(null);

  useEffect(() => {
    const valid = validateEmail(loginEmail);
    setLoginEmailValid(valid);  
  }, [loginEmail]);

  const validateEmail = (email) => {
    return validator.isEmail(email);
    };

  const [loginPassword, setLoginPassword] = useState('');

  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }
    const score = zxcvbn(password).score;
    return score >= 3;
  };

  const navigate = useNavigate();

  const signInWithEmail = async () => {
    console.log('Signing in with email...');
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      navigate('/home');
    } catch (error) {
      console.error(error);
    }
  };

  // const signUpWithEmail = async () => {
  //   console.log('Signing up with email...');
  //   try {
  //     await auth.createUserWithEmailAndPassword(signupEmail, signupPassword);
  //     navigate('/home');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [error, setError] = useState(null);

  const signUpWithEmail = async () => {
    console.log('Signing up with email...');
    try {
      await createUserWithEmailAndPassword(auth, signupEmail, signupPassword);
      navigate('/home');
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists. Please use a different email or sign in.');
      } else {
        setError('An error occurred while signing up. Please try again.');
      }
    }
  };

  const clearError = () => {
    setError(null);
  };

  // const auth = getAuth();
  // createUserWithEmailAndPassword(auth, signupEmail, signupPassword)
  //   .then((userCredential) => {
  //     console.log("User signed in");
  //     const user = userCredential.user;
  //     navigate('/home');
  //   })
  //   .catch((error) => {
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     console.log(errorCode);
  //   });

  const signInWithGoogle = async () => {
    console.log('Signing in with Google...');
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/home');
    } catch (error) {
      console.error(error);
    }
  };

const [formShown, setFormShown] = useState('signup');  


  return (
  <div className="login-container">
    <div className="left-column">
      <div className='lc-text'>
        <div className='lc-top'>
          <h1 className="lc-cta">Get the job.</h1>
          <h1 className="lc-cta2">Bite sized mock interviews in 15 minutes per day.</h1>
        </div>
        <div className='lc-bottom'></div>
          <h1 className="lc-logo">Dryrun</h1>
      </div>
    </div>
    <div className="right-column">
      <div className="rc-container">
        <div className='signup-login-toggle'>
          <h1 className={`login-header ${formShown === 'signup' ? 'activeHeader' : ''}`} onClick={() => setFormShown('signup')}>Sign up</h1> 
          <h1 className={`login-header ${formShown === 'login' ? 'activeHeader' : ''}`} onClick={() => setFormShown('login')}>Login</h1>
        </div>
        
        <div className='signup-form' style={formShown === 'signup' ? {} : { display: 'none' }}>
          <button className='login-button-google' onClick={signInWithGoogle}>Continue with Google</button>
          <p className="separator">OR EMAIL</p>
          <input type="email" className={signupEmailValid ? '' : 'email-invalid'} placeholder="user@dryrun.com" value={signupEmail} onChange={(e) => {setSignupEmail(e.target.value); const valid = validateEmail(e.target.value); if (valid) {setSignupEmailValid(true);} else {setSignupEmailValid(false);} }} onFocus={clearError}/>
          <p className={`email-error ${signupEmailValid === false ? 'show' : 'hide'}`}>Please enter a valid email address</p>
          
          <input type={"password"} className={signupPasswordValid ? '' : 'password-invalid'} placeholder="●●●●●●●●" value={signupPassword} onChange={(e) => setSignupPassword(e.target.value)} onFocus={clearError}	/>

          <p className={`password-error ${signupPasswordValid === false ? 'show' : 'hide'}`}>Please enter a stronger password</p>
          <p className='compliance-text'>By signing up, you accept Dryrun's Terms of Service and Privacy Policy</p>
          <button className={`login-button-email ${signupEmailValid && signupPasswordValid ? "login-button-email-enabled" : "login-button-email-disabled"}`} onClick={signUpWithEmail} disabled={!signupEmailValid || !signupPasswordValid}>Create an account with email</button>
          {error && <p className="error-message">{error}</p>}
          <p className={`login-header ${formShown === 'login' ? 'activeHeader' : ''}`} onClick={() => setFormShown('login')}>Already have an account? Login</p>
        </div>

        <div className='login-form' style={formShown === 'login' ? {} : { display: 'none' }}>
          <button className='login-button-google' onClick={signInWithGoogle}>Continue with Google</button>
          <p className="separator">OR EMAIL</p>
          <input type="email" className={loginEmailValid ? '' : 'email-invalid'} placeholder="user@dryrun.com" value={loginEmail} onChange={(e) => {setLoginEmail(e.target.value); const valid = validateEmail(e.target.value); if (valid) {setLoginEmailValid(true);} else {setLoginEmailValid(false);} }}/>
          {/* <p className={`email-error ${loginEmailValid === false ? 'show' : 'hide'}`}>Please enter a valid email address</p> */}
          <input type="password" placeholder="●●●●●●●●" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
          <button className={`login-button-email ${loginEmail.length > 0 && loginPassword.length > 0 ? "login-button-email-enabled" : "login-button-email-disabled"}`} onClick={signInWithEmail}>Login with email</button>
          <p className={`login-header ${formShown === 'signup' ? 'activeHeader' : ''}`} onClick={() => setFormShown('signup')}>New to Dryrun? Create a free account</p>
        </div> 

        
        </div>
    </div>
  </div>
  );
};

export default Login;