import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/pages/Login.js';
import ProtectedRoute from './routes/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import Home from './components/pages/Home';
import Profile from './components/pages/Profile';
import Settings from './components/pages/Settings';
import DefaultLayout from './layouts/DefaultLayout';
import PlainLayout from './layouts/PlainLayout';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <PlainLayout>
                <Login />
              </PlainLayout>
            }
          />
          <Route
            path="/home"
            element={
              <DefaultLayout>
                <ProtectedRoute element={<Home />} />
              </DefaultLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <DefaultLayout>
                <ProtectedRoute element={<Profile />} />
              </DefaultLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <DefaultLayout>
                <ProtectedRoute element={<Settings />} />
              </DefaultLayout>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;