import { React, useEffect } from 'react';
// import { handleShare } from './handleShare' --this is for making the share button work to send to others
import './Job.css';

// const toggleDropdown = () => {
//     setOpen(!open);
//   }

// const [open, setOpen] = useState(false);

// const dropdownRef = useRef(null);


//   useEffect(() => {
//     const close = e => {
//       if (!dropdownRef.current.contains(e.target)) {
//         setOpen(false); 
//       }  
//     };
  
//     document.addEventListener("click", close);    
//     return () => document.removeEventListener("click", close);
//   }, []);

// const deleteJob = (jobId) => {
//     fetch(`/api/jobs/${jobId}`, {
//       method: 'DELETE'  
//     })
//     .then(res => res.json())
//     .then(() => {
//       // Job deleted, do something...
//     })
//   }

const Job = ({ job }) => (
  <div className="job-card">
    <div className="job-info">
        <div className="company-logo-area">
            {/* <svg className="company-logo" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.9867 31.1721C32.3817 32.5696 31.6657 33.856 30.8361 35.0387C29.7053 36.651 28.7794 37.767 28.0658 38.3867C26.9597 39.404 25.7745 39.925 24.5054 39.9546C23.5943 39.9546 22.4956 39.6953 21.2166 39.1694C19.9335 38.646 18.7542 38.3867 17.676 38.3867C16.5452 38.3867 15.3324 38.646 14.0351 39.1694C12.7359 39.6953 11.6893 39.9694 10.8891 39.9966C9.67205 40.0484 8.459 39.5126 7.24817 38.3867C6.47536 37.7127 5.50872 36.5572 4.35073 34.9202C3.1083 33.1721 2.08685 31.145 1.28663 28.8339C0.429617 26.3377 0 23.9205 0 21.5803C0 18.8997 0.579242 16.5876 1.73945 14.6502C2.65128 13.0939 3.86433 11.8663 5.38255 10.9651C6.90078 10.0639 8.54122 9.60465 10.3078 9.57527C11.2745 9.57527 12.5421 9.87427 14.1173 10.4619C15.6882 11.0515 16.6968 11.3505 17.139 11.3505C17.4696 11.3505 18.5901 11.0009 20.4895 10.3039C22.2857 9.65749 23.8017 9.38984 25.0437 9.49527C28.409 9.76687 30.9373 11.0935 32.6188 13.4835C29.609 15.3072 28.1201 17.8614 28.1498 21.1381C28.1769 23.6904 29.1028 25.8143 30.9225 27.5006C31.7472 28.2833 32.6681 28.8883 33.6928 29.3179C33.4706 29.9623 33.236 30.5796 32.9867 31.1721ZM25.2684 0.800228C25.2684 2.80068 24.5375 4.6685 23.0808 6.39734C21.3228 8.45258 19.1965 9.6402 16.8906 9.4528C16.8612 9.21281 16.8442 8.96022 16.8442 8.6948C16.8442 6.77436 17.6802 4.71912 19.1649 3.03868C19.9061 2.18784 20.8487 1.48038 21.9919 0.916025C23.1326 0.360091 24.2116 0.0526484 25.2264 0C25.256 0.26743 25.2684 0.534902 25.2684 0.800228Z" fill="white"/>
            </svg> */}
            <img className="company-logo" src="https://media.licdn.com/dms/image/C560BAQHnBgJjFK23Ng/company-logo_100_100/0/1680111598396?e=1692230400&v=beta&t=nVkEnCEgL38Z9xEo9N8j_R5JhM7niU38A2dLZtS0LgA" alt="company logo"></img>
        </div>
        <div className="job-card-info">
            <p className="company">{job.companyName}</p>
            {/* Use {job.jobTitle} */}
            <p className="job-title">Product Lead, Internal Tools</p>
            <p className="job-summary">Lead high-performance global customer success team to increase revenue through</p>
        </div>
        <div className="click-indicator-div">
            {/* <button ref={dropdownRef} className={`dropdown-menu ${open && 'open'}`}> */}
                <svg className='dropdown-menu-icon' viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="10" r="2"/>
                    <circle cx="10" cy="10" r="2"/>
                    <circle cx="18" cy="10" r="2"/>
                </svg>
            {/* </button> */}
            {/* <button className="dropdown-item" 
            onClick={deleteJob}
            >Delete</button> */}
            
            <div className='chevron-container'>
                <svg className="chevron-right" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.534976 2.62786C-0.106704 2.02702 -0.106704 1.02655 0.534976 0.425708C1.14101 -0.141761 2.09882 -0.141932 2.70521 0.425374L11.371 8.53257C12.2094 9.31701 12.2098 10.6241 11.3715 11.4088L2.65136 19.5741C2.04533 20.142 1.08734 20.142 0.481128 19.5741C-0.160376 18.9736 -0.160376 17.9733 0.481128 17.3727L8.38102 9.97519L0.534976 2.62786Z" fill="white"/>
                </svg>
            </div>
        </div>
    </div>
    <div className="job-source-block">
        {/* Use {job.jobSource} */}
        <p className="job-source">From LinkedIn</p>
    </div>
    <div className="card-action-bar">
        <div className="action-icon-list">
            <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                <i class="action-icon fa-solid fa-circle-info"></i>
            </a>
            <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                <i class="action-icon fa-solid fa-arrow-up-from-bracket"></i>
            </a>
            <a href={job.url} target="_blank" rel="noopener noreferrer">
                <i class="action-icon fa-solid fa-up-right-from-square"></i>
            </a>
        </div>
        <button 
            className="primary-action-button" 
            // onClick={handleButtonClick} -- USE router to link to mock interview type selector screen
            >
                <svg className="button-icon" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6677 8.87095C16.8428 8.87095 17.0178 8.8126 17.154 8.67646L19.8769 5.95353C20.1492 5.68124 20.1492 5.25335 19.8769 5.00048C19.6046 4.72819 19.1767 4.72819 18.9239 5.00048L16.6677 7.23715L15.773 6.34248C15.5008 6.07018 15.0729 6.07018 14.82 6.34248C14.5477 6.61477 14.5477 7.04266 14.82 7.29552L16.1815 8.65699C16.3176 8.81258 16.4927 8.87095 16.6677 8.87095Z" fill="white"/>
<path d="M13.1282 5.44788C12.4085 2.10257 9.21881 -0.134012 5.83473 0.293654C2.46995 0.721542 0.0385655 3.65844 0.0972474 6.92604C0.0972474 7.21778 0.0194495 13.3833 0 15.4256C0 15.6979 0.291742 15.8535 0.525113 15.6979L4.37611 12.9944C5.48475 13.4028 6.67115 13.5195 7.83812 13.3251C11.4946 12.6832 13.9259 9.10449 13.1284 5.4479L13.1282 5.44788ZM7.09906 9.82403C7.00181 9.94073 6.84622 9.99908 6.69062 9.99908C6.37943 9.99908 6.12656 9.74624 6.12656 9.43502C6.12656 9.12381 6.37941 8.87097 6.69062 8.87097C7.02126 8.87097 7.2741 9.10436 7.2741 9.41555C7.25465 9.57115 7.21576 9.72679 7.09906 9.82403ZM7.72144 6.61485L7.52695 6.77044C7.3519 6.92604 7.17686 7.08163 7.15741 7.3345C7.13796 7.52899 7.13796 7.72349 7.13796 7.93741C7.13796 7.97631 7.13796 8.01521 7.11851 8.03466C7.11851 8.03466 7.09906 8.05411 7.02126 8.05411H6.88511H6.65172H6.22383C6.16548 8.05411 6.14603 8.03466 6.14603 7.97631C6.12658 7.54842 6.14603 7.06221 6.30163 6.71207C6.41833 6.45923 6.63227 6.28418 6.84622 6.12859C7.02126 6.01189 7.17686 5.87574 7.313 5.7007C7.5464 5.37006 7.5075 5.11721 7.4297 4.96162C7.29355 4.70878 7.00181 4.55318 6.67119 4.55318C6.2433 4.55318 5.91268 4.80602 5.77652 5.23389C5.73762 5.37004 5.71817 5.38948 5.65982 5.38948H5.54312C5.30973 5.35059 5.07634 5.33114 4.82347 5.29224C4.74567 5.29224 4.72622 5.25334 4.72622 5.19499C4.82347 4.18362 5.79592 3.6585 6.71007 3.6585C7.23518 3.6585 7.74091 3.83355 8.09096 4.12529C8.4216 4.41703 8.61607 4.806 8.61607 5.21446C8.61607 5.79795 8.14929 6.22583 7.7214 6.61482L7.72144 6.61485Z" fill="white"/>
<path d="M23.9029 6.92605C23.9613 3.65853 21.5495 0.721773 18.1459 0.293662C16.162 0.0408183 14.2365 0.702101 12.8556 1.96632C13.1279 2.29696 13.3613 2.6665 13.5753 3.03602C14.7033 1.92737 16.3177 1.32446 17.9903 1.53838C20.6743 1.88847 22.6777 4.18351 22.6386 6.90644C22.6386 7.13984 22.6775 10.8547 22.7164 13.5971L19.8184 11.5743L19.196 11.8077C18.3208 12.1384 17.3483 12.2356 16.3953 12.0606C15.3061 11.8855 14.3337 11.3798 13.5751 10.6213C13.4584 10.8158 13.3417 11.0103 13.2056 11.2048C13.0889 11.3604 12.9722 11.5354 12.8555 11.691C13.7501 12.5079 14.8782 13.0914 16.1619 13.3248C17.3288 13.5193 18.5153 13.422 19.6239 12.9942L23.4749 15.6976C23.6888 15.8532 24 15.6976 24 15.4253C23.9805 13.3831 23.9027 7.21786 23.9027 6.92578L23.9029 6.92605Z" fill="white"/>
</svg>

                <p className="button-text">Select an interview</p>
        </button>
    </div>
    {/* <p className="job-url">{job.url}</p>
    <p className="job-text">{job.text}</p>
    <p className="job-companyname">{job.companyName}</p> */}
  </div>
);

export default Job;


