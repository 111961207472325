// BottomNavbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BottomNavbar.css';

const BottomNavbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="bottom-navbar">
      <nav>
        {[
          { path: '/home', icon: 'fa-solid fa-house', label: 'Home' },
          { path: '/profile', icon: 'fa-solid fa-user', label: 'Profile' },
          { path: '/settings', icon: 'fa-solid fa-gear', label: 'Settings' },
        ].map((tab, index) => (
          <Link
            key={`tab-${index}`}
            to={tab.path}
            className={`nav-link ${isActive(tab.path) ? 'active' : ''}`}
          >
            <i className={`nav-icon ${tab.icon}`}></i>
            <span>{tab.label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default BottomNavbar;