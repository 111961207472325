import React, { useState } from 'react';
import CollapsibleSidebar from '../components/pages/CollapsibleSidebar';
import BottomNavbar from '../components/BottomNavbar';
import './DefaultLayout.css';

const DefaultLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="default-layout-content-container">
      <CollapsibleSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className={`default-layout-content${collapsed ? ' collapsed' : ''}`}>{children}</div>
      <BottomNavbar />
    </div>
  );
};

export default DefaultLayout;