import React from 'react';
import './DropdownMenu.css';

const DropdownMenu = ({ handleLogout }) => {
  return (
    <ul className="dropdown-menu">
      {/* Add more options here as needed */}
      <li onClick={handleLogout} className="dropdown-menu-item">
        Logout
      </li>
    </ul>
  );
};

export default DropdownMenu;