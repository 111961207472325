import React from 'react';
import Job from '../Job';
import useJobDescriptions from '../Jobs';
import './Home.css';

const Home = () => {
  const jobDescriptions = useJobDescriptions();

  return (
    <>
      <div className='home-content'>
        <div className='home-top-content'>
          <h1 className='home-header'>Home</h1>
        </div>
          <div className='content-columns-home'></div>
            <div className='left-column-home'>
              <div className='saved-jobs'>
                <h2 className='section-subheader'>Saved Jobs</h2>
                {jobDescriptions.map((job, index) => (
                  <Job key={index} job={job} />
                ))}
              </div>
            </div>
            {/* <div className='right-column-home'>
                  <div className='job-details-expanded'>
                    <h2 className='jde-job-title'>Job Title</h2>
                    <h2 className='jde-job-description'>Job Description</h2>
                  </div>
            </div> */}
          
        </div>
    </>
  );
};

export default Home;