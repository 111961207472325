import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import DropdownMenu from '../DropdownMenu';
import '../DropdownMenu.css'
import './CollapsibleSidebar.css';

const CollapsibleSidebar = ({ collapsed, setCollapsed }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false); // Add this line
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('logout clicked');
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="toggle-btn-container">
        <button className="toggle-btn" onClick={toggleSidebar}>
          {collapsed ? 'Expand' : 'Collapse'}
        </button>
      </div>
      
      <div className="sidebar-content">
        <h2>Dashboard</h2>
        <nav>
          <Link to="/home">Home</Link>
          <Link to="/profile">Profile</Link>
          <Link to="/settings">Settings</Link>
          {/* Add more links as needed */}
        </nav>
        <button className="dropdownMenuButton" onClick={toggleDropdown}>
          Logout
        </button>
        {dropdownVisible && <DropdownMenu handleLogout={handleLogout} />}
      </div>
    </div>
  );
};

export default CollapsibleSidebar;