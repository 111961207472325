import React from 'react';
import './Settings.css';

const Settings = () => {

  return (

    <div className='settings-page'>
      <div className='settings-content-container'>
        <h1 className='settings-header'>Settings</h1>
      </div>
    </div>
  );
};

export default Settings;