import React from 'react';
import './Profile.css';

const Profile = () => {

  return (

    <div className='profile-page'>
      <div className='profile-content-container'>
        <h1 className='profile-header'>Profile</h1>
      </div>
    </div>
  );
};

export default Profile;