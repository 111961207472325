import { useState, useEffect } from 'react';

const useJobDescriptions = () => {
  const [jobDescriptions, setJobDescriptions] = useState([]);

  const fetchJobDescriptions = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/job-descriptions');
      console.log('Fetch response:', response);

      if (!response.ok) {
        throw new Error('Failed to fetch job descriptions');
      }

      const data = await response.json();
      console.log('Fetched data:', data);

      setJobDescriptions(data.data);
    } catch (error) {
      console.error('Error fetching job descriptions:', error);
    }
  };

  useEffect(() => {
    fetchJobDescriptions();

    // Create a WebSocket connection
    const socket = new WebSocket('ws://localhost:8081'); // Change the URL to match your WebSocket server address

    socket.addEventListener('message', (event) => {
      if (event.data === 'data_updated') {
        fetchJobDescriptions();
      }
    });

    return () => {
      socket.close();
    };
  }, []);

  return jobDescriptions;
};

export default useJobDescriptions;