// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// Import Firebase Auth
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA3YHHdaWdPWp9BQlhLHMkCFIajc8gPHXw",
    authDomain: "speltmelk-d9dfd.firebaseapp.com",
    projectId: "speltmelk-d9dfd",
    storageBucket: "speltmelk-d9dfd.appspot.com",
    messagingSenderId: "300858132833",
    appId: "1:300858132833:web:4371db613168249700dd20",
    measurementId: "G-TP78XCYXPD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Export the Firebase app and auth instances
export { app as firebase, auth };